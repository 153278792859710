import React, { useState } from 'react'
import SideMenu from './layouts/SideMenu'
import TopBar from './layouts/TopBar'
import MobileMenu from './layouts/MobileMenu'
import { DataGrid } from '@mui/x-data-grid';
import GeneralReport from './layouts/GeneralReport';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Modal } from '@mui/material';
import './cssClasses/custom.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getBearerToken, postBearerToken, putBearerToken } from '../../utils/Apis';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'

const A_cloud_recordings = () => {
    React.useEffect(() => {
        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();
        return () => {
            // document.body.removeChild(externalScript);
        };
    }, []);


    const convertUTCtoIST = (utcDate) => {
        const date = new Date(utcDate);
        return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
    };

    const navigate = useNavigate()
    const [rows, setrows] = React.useState([])
    const [dataToken, setdataToken] = useState("")
    const [rendered, setrendered] = useState(0)
    React.useEffect(() => {
        fetch_users()
    }, [rendered])




    const fetch_users = async () => {
        getBearerToken("cloud_recordings").then((result) => {
            if (result.error == 0) {
                console.log(result)
                setrows(result.data?.meetings)
                setdataToken(result.token)
            } else {
                // notify(JSON.stringify(result.messages))
            }
        })
    }

    const deleteAllFilesOfRecording = async (item) => {


        const confirmDelete = window.confirm("Are you sure you want to delete this Room?");

        if (!confirmDelete) return;

        console.log(item)

        for (let i = 0; i < item.recording_files.length; i++) {

            let recording_files=item.recording_files[i]
            let meeting_id=recording_files?.meeting_id
            let rec_id=recording_files?.id


            const doubleEncodedUUID = encodeURIComponent(encodeURIComponent(meeting_id));


            
            console.log(doubleEncodedUUID)

            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: `https://api.zoom.us/v2/meetings/${doubleEncodedUUID}/recordings/${rec_id}`,
                headers: {
                    'Authorization': 'Bearer ' + dataToken,
                }
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        await fetch_users()

    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: "grid-header",
            flex: 1,

        },
        {
            field: 'topic',
            headerName: 'Name',
            headerClassName: "grid-header",
            flex: 1,

        },

        {
            field: 'link',
            headerName: 'Link',
            headerClassName: "grid-header",
            flex: 1,
            renderCell: (params) => {
                return <a style={{ textDecoration: "underline", color: "blue" }} target='_blank' href={params.row?.share_url + "?pwd=" + params.row?.recording_play_passcode} > View Recordings </a>
            }

        },
        {
            field: 'start_time',
            headerName: 'Start Time',
            headerClassName: "grid-header",
            flex: 1,
            renderCell: (params) => { return <label> {convertUTCtoIST(params.row?.start_time)} </label> }

        },
        {
            headerName: 'Delete',
            headerClassName: "grid-header",
            flex: 1,
            renderCell: (params) => { return <label style={{cursor:"pointer"}} onClick={() => deleteAllFilesOfRecording(params.row)}> Delete </label> }

        },


    ];


    return (
        <div className="py-5 md:py-0">
            {/* <!-- BEGIN: Mobile Menu --> */}
            <div className="mobile-menu md:hidden">
                <MobileMenu />

            </div>
            {/* <!-- END: Mobile Menu -->
        <!-- BEGIN: Top Bar --> */}
            <TopBar level1={"User Management"} />
            {/* <!-- END: Top Bar --> */}
            <div className="flex overflow-hidden">
                {/* <!-- BEGIN: Side Menu --> */}
                <SideMenu />
                {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
                <div className="content">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-12">

                            <div class="grid grid-cols-12 gap-6 mt-5">
                                <div class="intro-y col-span-12 lg:col-span-6">

                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-6">
                                {/* <!-- BEGIN: General Report --> */}

                                <div className="col-span-12 mt-6">
                                    <div className="intro-y block sm:flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            Cloud Recordings
                                        </h2>
                                        {/* <div className="flex items-center sm:ml-auto mt-3 sm:mt-0">
                                            <button onClick={() => handleOpenAddModal()} className="btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="plus" className="hidden sm:block w-4 h-4 mr-2"></i> Add New </button>
                                            <button className="ml-3 btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to Excel </button>
                                            <button className="ml-3 btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to PDF </button>
                                        </div> */}
                                    </div>
                                    <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                                        <DataGrid
                                            sx={{ mt: 1 }}
                                            rows={rows}
                                            columns={columns}
                                            getRowId={(data) => data.uuid}
                                            showCellVerticalBorder
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 15,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[9]}
                                        // onCellEditCommit={(params) => update(params)}
                                        />
                                    </div>

                                </div>
                                {/* <!-- END: Weekly Top Products --> */}
                            </div>
                        </div>

                    </div>
                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default A_cloud_recordings
