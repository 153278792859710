import React, { useState } from 'react'
import SideMenu from './layouts/SideMenu'
import TopBar from './layouts/TopBar'
import MobileMenu from './layouts/MobileMenu'
import { DataGrid } from '@mui/x-data-grid';
import users from "../assets/image/users.png"
import host from "../assets/image/host.png"
import room from "../assets/image/room.png"


import GeneralReport from './layouts/GeneralReport';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Modal } from '@mui/material';
import './cssClasses/custom.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getBearerToken, postBearerToken, putBearerToken } from '../../utils/Apis';
import { useNavigate } from 'react-router-dom';

const A_admin_list = () => {
    React.useEffect(() => {
        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();
        return () => {
            // document.body.removeChild(externalScript);
        };
    }, []);


    const navigate = useNavigate()
    const [rows, setrows] = React.useState([])
    const [rendered, setrendered] = useState(0)
    React.useEffect(() => {
        fetch_users()
    }, [rendered])


    const editAdmin = async (id, type) => {
        if (type == "E") {
            navigate("/edit_sub_admin", { state: { id: id } })
        } else {
            const confirmDelete = window.confirm("Are you sure you want to delete this user?");

            if (!confirmDelete) return; // Stop execution if the user cancels

            let current_date = new Date();
            putBearerToken("users?_id=" + id, {
                deleted_at: current_date,
                slug_name: current_date + id,
                client_id: current_date + id,
                client_secret: current_date + id,
                oauth_id: current_date + id,
                s2s_client_id: current_date + id,
                s2s_client_secret: current_date + id,
            }).then((result) => {
                if (result.error == 0) {

                    setrendered(o => o + 1)

                } else {


                }

            })

        }

    }

    const fetch_users = async () => {
        getBearerToken("users?for_admin=1").then((result) => {
            if (result.error == 0) {
                console.log(result.data)
                setrows(result.data)
            } else {
                // notify(JSON.stringify(result.messages))
            }
        })
    }


    const columns = [
        {
            field: 'first_name',
            headerName: 'First Name',
            headerClassName: "grid-header",
            flex: 1,

        },
        {
            field: 'phone',
            headerName: 'Phone',
            headerClassName: "grid-header",
            flex: 1,

        },
        {
            field: 'slug_name',
            headerName: 'User Name',
            headerClassName: "grid-header",
            flex: 1,

        },
        {
            field: 'show_password',
            headerName: 'Password',
            headerClassName: "grid-header",
            flex: 1,

        },

        {
            field: 'type',
            headerName: 'Type',
            headerClassName: "grid-header",
            flex: 1,
            valueGetter: (params) => params.row.type == "SubAdmin" ? "Host" : "User"

        },
        {
            field: 'room',
            headerName: 'Room',
            headerClassName: "grid-header",
            flex: 1,
            valueGetter: (params) => params.row?.room?.name

        },
        {
            field: 'device',
            headerName: 'Device',
            headerClassName: "grid-header",
            flex: 1,
            // valueGetter: (params) => !params.row?.device_id ? "Device Id" : ""
            renderCell: (params) => {
                return (
                    <>
                        {params.row?.device_id != "" ? <span style={{ color: 'green' }}>{params?.row?.device_id}</span> : ""}
                    </>
                )
            }

        },

        {
            field: 'is_active',
            headerName: 'Status',
            headerClassName: "grid-header",
            flex: 1,
            renderCell: (params) => (
                <div className={params.row.is_active == true ? "status-bg-green" : "status-bg-red"}>
                    {params.row.is_active == true ? "Active" : "Inactive"}
                </div>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            headerClassName: "grid-header",
            flex: 3,
            renderCell: (params) => (
                <div style={{ width: "100%", borderWidth: 1, borderRadius: 3, padding: 10, display: "flex", justifyContent: "start", alignContent: "center", alignItems: "center", flexDirection: "row" }}>
                    <button onClick={() => editAdmin(params.row._id, "E")} style={{ cursor: "pointer" }} className="side-menu__icon mr-2 bg-primary text-white btn"> <i data-lucide="edit"></i> Edit</button>
                    <button onClick={() => editAdmin(params.row._id, "D")} style={{ cursor: "pointer" }} className="side-menu__icon bg-primary text-white btn"> <i data-lucide="trash-2"></i> Delete</button>

                    {params.row.device_id != "" && < button className='btn bg-primary text-white ' onClick={() => changeStatus(params.row._id, params.row.device_id)}>Reset Device</button>}
                </div >
            )
        },

    ];

    const changeStatus = (id, device_id) => {

        putBearerToken("users?_id=" + id, {
            device_id: device_id == "" ? "C" : "",
        }).then((result) => {
            if (result.error == 0) {
                fetch_users()
                setrendered(o => o + 1)

            } else {


            }

        })
    }


    return (
        <div className="py-5 md:py-0">
            {/* <!-- BEGIN: Mobile Menu --> */}
            <div className="mobile-menu md:hidden">
                <MobileMenu />

            </div>
            {/* <!-- END: Mobile Menu -->
        <!-- BEGIN: Top Bar --> */}
            <TopBar level1={"User Management"} />
            {/* <!-- END: Top Bar --> */}
            <div className="flex overflow-hidden">
                {/* <!-- BEGIN: Side Menu --> */}
                <SideMenu />
                {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
                <div className="content">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-12">

                            <div className="grid grid-cols-12 gap-6 mt-5">
                                <div className="intro-y col-span-12 lg:col-span-6">

                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-3">
                                <div className="col-span-3">
                                    <div className="bg-primary flex w-full rounded-md   p-3" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <div className="">
                                            <h3 style={{ fontSize: "22px" }}>{rows.filter(i => i.type == "User").length}</h3>
                                            <h5 style={{ fontSize: "20px" }}>Total Users</h5>
                                        </div>
                                        <div className="">
                                            <img src={users} className='bg-white  p-3 rounded-full' alt="" style={{ width: "80px" }} />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-span-3">
                                    <div className="bg-success flex w-full rounded-md   p-3" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <div className="">
                                            <h3 style={{ fontSize: "22px" }}>{rows.filter(i => i.type == "SubAdmin").length}</h3>
                                            <h5 style={{ fontSize: "20px" }}>Total Host</h5>
                                        </div>
                                        <div className="">
                                            <img src={host} className='bg-white  p-3 rounded-full' alt="" style={{ width: "80px" }} />
                                        </div>
                                    </div>

                                </div>
                                {/* <div className="col-span-3">
                                    <div className="bg-primary flex w-full rounded-md   p-3" style={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <div className="">
                                            <h3 style={{ fontSize: "22px" }}>2</h3>
                                            <h5 style={{ fontSize: "20px" }}>Total Rooms</h5>
                                        </div>
                                        <div className="">
                                            <img src={room} className='bg-white  p-3 rounded-full' alt="" style={{ width: "80px" }} />
                                        </div>
                                    </div>

                                </div> */}
                            </div>


                            <div className="grid grid-cols-12 gap-6">
                                {/* <!-- BEGIN: General Report --> */}

                                <div className="col-span-12 mt-6">
                                    <div className="intro-y block sm:flex items-center h-10">
                                        <h2 className="text-lg font-medium truncate mr-5">
                                            Users
                                        </h2>
                                        {/* <div className="flex items-center sm:ml-auto mt-3 sm:mt-0">
                                            <button onClick={() => handleOpenAddModal()} className="btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="plus" className="hidden sm:block w-4 h-4 mr-2"></i> Add New </button>
                                            <button className="ml-3 btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to Excel </button>
                                            <button className="ml-3 btn box flex items-center text-slate-600 dark:text-slate-300"> <i data-lucide="file-text" className="hidden sm:block w-4 h-4 mr-2"></i> Export to PDF </button>
                                        </div> */}
                                    </div>
                                    <div className="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
                                        <DataGrid
                                            sx={{ mt: 1 }}
                                            rows={rows}
                                            columns={columns}
                                            getRowId={(data) => data._id}
                                            showCellVerticalBorder
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 15,
                                                    },
                                                },
                                            }}
                                            pageSizeOptions={[9]}
                                        // onCellEditCommit={(params) => update(params)}
                                        />
                                    </div>

                                </div>
                                {/* <!-- END: Weekly Top Products --> */}
                            </div>
                        </div>

                    </div>
                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default A_admin_list